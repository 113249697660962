<template>
<div class="headerWrapper">
    <header class="main-header">
        <div class="menuContainer">
            <nav class="navContainer">
                <div id="mainMenu">
                    <ul class="navMenu">
                        <li>
                            <RouterLink :to="Tr.i18nRoute({ name: 'home' })">{{ $t("nav.home") }}</RouterLink>
                        </li>
                        <li>
                            <RouterLink :to="Tr.i18nRoute({ name: 'products', params: { productUrl: $t(`router.defaultProduct`) } })">{{ $t("nav.products") }}</RouterLink>
                        </li>
                        <!-- <li>
                            <RouterLink :to="Tr.i18nRoute({ name: 'references' })">{{ $t("nav.references") }}</RouterLink>
                        </li> -->
                        <li>
                            <RouterLink :to="Tr.i18nRoute({ name: 'about' })">{{ $t("nav.about") }}</RouterLink>
                        </li>
                    </ul>
                </div>
            </nav>
            <div class="burgerMenu" @click="$emit('activateBurgerMenu')">
                <div class="dash"></div>
                <div class="dash"></div>
                <div class="dash"></div>
            </div>
            <languageSwitcher class="languageFlags" @localeChanged="$emit('localeChanged')" />
        </div>
    </header>
</div>
</template>

<script>
import Tr from "@/i18n/translation"
import languageSwitcher from "@/components/languageSwitcher.vue"

export default {
    name: 'staticMenu',
    setup() {
        return { Tr } 
    },
    components: {
        languageSwitcher,
    },
    methods: {
        
    }
}
</script>

<style scoped>
.headerWrapper {
    background: rgb(219, 221, 226);
}
[data-target="#mainMenu"] {
  position: relative;
  z-index: 999;
}
.name {
    color: #BEC4CD;
    position: absolute;
    top: 25px;
    right: 30px;
    bottom: 0;
    margin: auto;
    font-size: 1.2rem;
}
.name:hover {
    color: #dce3ee;
    cursor: default;
}
.navMenu {
    list-style-type: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
}
.navContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.menuContainer {
    position: relative;
    width: 1300px;
    margin: 0 auto;
}
#mainMenu li {
    margin-top: 0px;
}
#mainMenu li > a {
  font-size: 18px;
  letter-spacing: 1px;
  color: rgb(50, 50, 50);
  font-weight: bold;
  position: relative;
  z-index: 1;
  text-decoration: none;
}

.main-header.fixed-nav #mainMenu li > a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

#mainMenu li:not(:last-of-type) {
  margin-right: 50px;
}

#mainMenu li > a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1px;
  background: rgb(0, 0, 0);
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;

  -webkit-transition: transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#mainMenu li > a:hover::before,
#mainMenu li > a.active::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav #mainMenu li > a::before {
  background: #000;
}

.main-header.vue-fixed-header {
    position: fixed;
    top: -400px;
    left: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.main-header {
    overflow: hidden;
    z-index: 99;
    width: 100%;
    height: 80px;
    background: rgb(226, 228, 233);
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
}
.main-header.vue-fixed-header--isFixed {
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-box-shadow: 0px 2px 3px -2px rgba(0,0,0,0.5);
    box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5);
}
.main-header.fixed-nav {
  top: 0;
  background: rgb(0, 0, 0);
  -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav .navbar-brand > img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand > img:first-of-type {
  display: none;
}
.main-header .navbar-brand img {
  max-width: 40px;
  margin-right: 10px;
  -webkit-animation: fadeInLeft 0.4s both 0.4s;
          animation: fadeInLeft 0.4s both 0.4s;
}
.main-header .navbar-brand img {
  max-width: 40px;
  margin-right: 10px;
  -webkit-animation: fadeInLeft 0.4s both 0.4s;
          animation: fadeInLeft 0.4s both 0.4s;
}
a:hover {
    cursor: pointer;
}
.burgerMenu {
    display: none;
    position: absolute;
    top: 20px;
    left: 25px;
    cursor: pointer;
}
.dash {
    height: 3px;
    width: 28px;
    border-radius: 100px;
    background: #4A154B;
    margin-bottom: 5px;
}
.buttons button {
    position: absolute;
    top: 24px;
    bottom: 10px;
    right: 0;
    margin: auto;
    height: 40px;
    z-index: 5;
    color: #4A154B;
    font-size: 18px;
    font-weight: bold;
    font-family: 'DM sans', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    background: transparent;
    border-radius: 7px;
    border: none;
    border: 1px solid #4A154B;
    -webkit-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
}
button:hover {
    /* background: rgb(252,70,107);
    background: linear-gradient(45deg, rgba(252,70,107,1) 0%, rgba(121,2,82,1) 100%);  */
    -webkit-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
    background-position: 179px; 
}

.languageFlags {
    position: absolute;
    right: 0;
    top: 13px;
    bottom: 0;
    margin: auto;
}
@media (max-width: 1350px) {
    .menuContainer {
        width: 100%;
    }
    .navMenu {
        margin-left: 25px;
    }
    .languageFlags {
        right: 25px;
    }
}
@media (max-width: 750px) {
    #mainMenu li:not(:last-of-type) {
        margin-right: 25px;
    }
}
@media (max-width: 650px) {
    .navMenu li {
        display: none;
    }
    .burgerMenu {
        display: initial;
        position: absolute;
    }
}
</style>
