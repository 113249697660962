<template>
    <div>
        <div class="content">
            <div class="references">
                <referenceCard 
                    class="referencesCard"
                    @openProduct='product'
                    v-for="reference of references" :key="reference.id"
                    :image="reference.coverImage"
                    :refImages="reference.refImages"
                    :name="reference.name"
                    :architect="reference.architect"
                    :date="reference.date"
                    :products="reference.products"
                />
            </div>
        </div>
    </div>
</template>

<script>
import referenceCard from "@/components/references/card.vue"

export default {
    name: 'aboutView',
    components: {
        referenceCard
    },
    props: {
        productList: null,
        references: null
    },
    data() {
        return {

        }
    },  
    methods: {
        product(productName) {
            for(const element of this.productList) {
                if(element.name == productName) {
                    this.openProduct(element)
                } 
            }
        },

        openProduct(product) {
            this.$emit('openProduct', product)
        }
    },

    created() {
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Referentsid',
        })
    }
}
</script>
<style scoped>
.content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    margin-top: 190px;
    padding-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.references {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
/* .referencesCard:not(:nth-child(2)) {
    margin-right: 36px;
} */
.referencesCard {
    margin-bottom: 30px;
}
@media (max-width: 1350px) {
    .content {
        width: 95%;
    }
}
@media (max-width: 950px) {
    .content {
        width: 100%;
    }
}
@media (max-width: 650px) {
    .content {
        margin-top: 145px;
        padding-top: 30px;
    }
}
</style>
