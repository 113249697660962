<template>
    <div class="wrapper">
        <div class="tabs">
            <div 
                class="singleTab"
                :class="{'singleTabActive': activeTab == index}"
                :style="{ width: singleTabWidth + '%' }"
                @click="activateTab(index)"
                v-for="(element, index) in textSections" :key="element.id"
            >
                <h2>{{ element.header }}</h2>
                <p>{{ element.smallText }}</p>
            </div>
        </div>
        <div class="textSection">
            <div class="textContainer">
                <h2>{{ activeTabData.title }}</h2>
                <p v-html="activeTabData.text"></p>
            </div>
            <div class="imageContainer">
                <img 
                    class="image"
                    :src="require(`@/assets/images/${activeTabData.image}`)" 
                    alt=""
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'textSections',
    props: {
        textSections: null
    },
    watch: {
        textSections() {
            this.activateTab(this.activeTab)
        }
    },
    data() {
        return {
            activeTab: 0,
            activeTabData: null,
            singleTabWidth: null
        }
    },  
    methods: {
        activateTab(index) {
            this.activeTab = index
            this.activeTabData = this.textSections[index]
        },

        calcSingleTabWidth() {
            if(window.innerWidth > 750) {
                this.singleTabWidth = (100 / this.textSections.length) - 1
            } else {
                this.singleTabWidth = 100
            }
        }
    },
    created() {
        this.calcSingleTabWidth()
        this.activateTab(0)
        window.addEventListener('resize', this.calcSingleTabWidth)
    },
    unmounted() {
        window.removeEventListener('resize', this.calcSingleTabWidth)
    }
}
</script>
<style scoped>
.wrapper {
    margin-top: 75px;
    margin-bottom: 50px;
}
.tabs {
    width: 1300px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.singleTab {
    text-align: start;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    border: 1px solid transparent;
}
.singleTab:hover {
    border: 1px solid rgba(43, 79, 119, 0.2);
}
.singleTab h2 {
    padding-left: 15px;
    padding-top: 10px;
}
.singleTab p {
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 14px;
}
.singleTabActive {
    margin-top: 10px;
    margin-bottom: -10px;
    background: rgb(66, 74, 95);
    color: white;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.textSection {
    width: 1225px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 25px 25px 25px 50px;
    border-radius: 10px;
    background: rgb(237, 239, 243);
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
}
.textContainer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}
.textSection h2 {
    margin-bottom: 25px;
    font-size: 28px;
}
.textSection p {
    padding-right: 50px;
    color: rgb(50,50,50);
}
.imageContainer {
    width: 650px;
}
.image {
    width: 650px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 15px rgba(0,0,0,0.08);
            box-shadow: 0px 5px 15px rgba(0,0,0,0.08);
}
@media (max-width: 1350px) {
    .tabs {
        width: 95%;
    }
    .textSection {
        width: 90%;
    }
}
@media (max-width: 1050px) {
    .textSection {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        border-radius: 0;
        padding-bottom: 50px;
    }
    .imageContainer {
        width: 100%;
    }
    .image {
        margin-top: 25px;
        width: 96%;
    }
}
@media (max-width: 830px) {
    .wrapper {
        margin-bottom: 0px;
    }
}
@media (max-width: 750px) {
    .wrapper {
        margin-top: 20px;
    }
    .tabs {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .singleTab {
        width: 100%;
        margin-bottom: 10px;
    }
    .singleTab h2 {
        font-size: 20px;
    }
    .singleTab p {
        font-size: 13px;
    }
    .textSection {
        margin-top: 10px;
    }
    .image {
        width: 95%;
    }
}
@media (max-width: 450px) {
    .textSection {
        padding: 25px 25px 50px 25px;
    }
    .textSection h2 {
        margin-bottom: 20px;
        font-size: 24px;
    }
    .textSection p {
        font-size: 14px;
        padding-right: 25px;
    }
}
</style>