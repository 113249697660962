<template>
    <div class="homeView">
        <mainBanner
            @click="$emit('closeBurgerMenu')"
            @openContactPopup="$emit('openContactPopup')" 
            @scrollToProducts="scrollToProducts()"
        />
        <!-- <h2>{{ $t("home.header") }}</h2> -->
        <!-- <div class="roundedDivider"></div> -->
        <textSections
            v-if="textSections"
            :textSections="textSections"
        />
        
        <div class="productSection">
            <div class="titleWrapper">
                <h2 class="leftAlignedTitled" id="productsSector">{{ $t("home.header2") }}</h2>
                <div class="roundedDivider" id="roundedDividerLeft"></div>
            </div>
    
            <div class="products">
                <productCard 
                    class="productCard"
                    @click="openProduct(product)"
                    v-for="product of productList" :key="product.id"
                    :image="product.coverImage"
                    :name="product.name"
                    :category="product.category"
                    :button="product.button"
                    :productUrl="product.productUrl"
                    :shortDescription="product.shortDescription"
                    :imgAssetDir="product.imgAssetDir"
                />
            </div>
        </div>
        
        <!-- <div class="titleWrapper">
            <h2 class="leftAlignedTitled" id="refSector">{{ $t("home.header3") }}</h2>
            <div class="roundedDivider" id="roundedDividerLeft"></div>
        </div>

        <div class="references">
            <referenceCard 
                class="referencesCard"
                @openProduct='product'
                v-for="reference of references" :key="reference.id"
                :image="reference.coverImage"
                :refImages="reference.refImages"
                :name="reference.name"
                :architect="reference.architect"
                :date="reference.date"
                :products="reference.products"
            />
        </div> -->
        
        <div class="titleWrapper">
            <h2 class="leftAlignedTitled" id="partnersTitle">{{ $t("home.header4") }}</h2>
            <div class="roundedDividerSmall"></div>
            <div class="logos" v-if="partners">
                <Carousel
                    id="carousel"
                    :items-to-show="itemsToShow"
                    :wrap-around="true"
                    :autoplay="1000"
                    :transition="3000"
                    :pauseAutoplayOnHover="true"
                    snapAlign="start"
                    v-model="currentSilde"
                    ref="carousel"
                >
                    <Slide id="slide" v-for="slide of partners" :key="slide.id" @click="openPartnerLink(slide.homePageUrl)">
                        <img 
                            class="partnerLogo"
                            :src="require(`@/assets/images/partners/${slide.logoImg}`)" 
                            alt=""
                        >
                    </Slide>
                </Carousel>
            </div>
        </div>
    </div>
</template>

<script>
import mainBanner from "@/components/home/banner.vue"
import textSections from "@/components/home/textSections.vue"
import productCard from "@/components/product/card.vue"
// import referenceCard from "@/components/references/card.vue"
import tr from "@/i18n/translation"

import { Carousel, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'homeView',
    props: {
        localeChanged: {},
        productList: null,
        references: null,
        textSections: null,
    },
    components: {
        mainBanner,
        productCard,
        // referenceCard,
        textSections,
        Carousel,
        Slide,
    },
    data() {
        return {
            currentSilde: 0,
            itemsToShow: 5,
            partners: null
        }
    },
    methods: {
        openPartnerLink(link) {
            const newTab = window.open(link, '_blank');
            newTab.focus();
        },

        scrollToProducts() {
            document.getElementById("productsSector").scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        },

        getLocaleData() {
            const promise = tr.productList()
            promise.then((result) => {
                this.partners = result.partners
            })
        },

        product(productName) {
            for(const element of this.productList) {
                if(element.name == productName) {
                    this.openProduct(element)
                } 
            }
        },

        openProduct(product) {
            this.$emit('openProduct', product)
        },

        handleResize() {
            let width = window.innerWidth
            if(width < 1350) {
                this.itemsToShow = 4
            }
            if(width < 900) {
                this.itemsToShow = 3
            }
            if(width < 400) {
                this.itemsToShow = 2
            }
            if(width > 400) {
                this.itemsToShow = 3
            }
            if(width > 900) {
                this.itemsToShow = 4
            }
            if(width > 1350) {
                this.itemsToShow = 5
            }
        }
    },
    created() {
        this.getLocaleData()
        this.handleResize()

        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Avaleht',
        })
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.handleResize);
    },
}
</script>
<style scoped>
.titleWrapper {
    width: 1300px;
    margin: 0 auto;
}
h2 {
    margin-top: 0px;
    font-size: 40px;
    color: #312d38;
}
.leftAlignedTitled {
    text-align: start;
}
.roundedDivider {
    width: 120px;
    height: 4px;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 50px;
    background: #4A154B;
}
#roundedDividerLeft {
    margin-left: 0px;
}
.references {
    width: 1300px;
    margin: 0 auto;
    margin-bottom: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.referencesCard {
    margin-bottom: 50px;
}
.productSection {
    width: 100%;
    padding-top: 50px;
    background: #F6F9FC;
}
.products {
    width: 1300px;
    margin: 0 auto;
    margin-bottom: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.productCard {
    margin-bottom: 65px;
    margin-right: 5px;
}
#carousel {
    width: 100%;
}
#slide {
    height: 120px;
    border: 1px solid transparent;
    border-radius: 5px;
}
#slide:hover {
    border: 1px solid rgba(0, 0, 0, 0.08);
    cursor: pointer;
    background: rgb(250,250,250);
}
.logos {
    position: relative;
    padding-top: 50px;
    width: 1300px;
    margin-bottom: 75px;
}
#partnersTitle {
    margin-top: 0px;
    font-size: 32px;
}
.partnerLogo {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
}
.roundedDividerSmall {
    margin-top: 10px;
    width: 120px;
    height: 3px;
    border-radius: 4px;
    background: #4A154B;
}
@media (max-width: 1350px) {
    .titleWrapper {
        width: 100%;
    }
    .references {
        width: 96%;
        margin-left: 25px;
    }
    .products {
        width: 96%;
        margin-left: 25px;
    }
    .logos {
        width: 96%;
        margin-left: 25px;
    }
    h2 {
        padding-left: 15px;
        padding-right: 15px;
    }
    #roundedDividerLeft {
        margin-left: 25px;
    }
    .roundedDividerSmall {
        margin-left: 20px;
    }
}
@media (max-width: 950px) {
    .references {
        width: 100%;
        margin-left: 0px;
    }
    .referencesCard {
        margin-bottom: 25px;
    }
}
@media (max-width: 830px) {
    .productSection {
        padding-top: 75px;
    }
    #productsSector {
        margin-top: -50px;
        text-align: center;
    }
    #refSector {
        margin-top: -30px;
        text-align: center;
    }
    #roundedDividerLeft {
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .productCard {
        margin: 0 auto;
        margin-bottom: 25px;
    }
    .products {
        width: 100%;
        margin-left: 0px;
    }
}
@media (max-width: 550px) {
    .titleWrapper h2 {
        font-size: 30px;
    }
    #partnersTitle {
        font-size: 30px;
        text-align: center;
    }
    .roundedDividerSmall {
        margin: 0px auto;
        margin-top: 15px;
    }
}
@media (max-width: 470px) {
    .logos {
        width: 90%;
        padding-top: 20px;
        margin: 0 auto;
        margin-bottom: 25px;
    }
}
</style>
