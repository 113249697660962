<template>
    <div class="referencesCard" :class="{ 'referencesCardSmall': $route.name == 'references' }">
        <div class="wrapper">
            <h2>{{ name }}</h2>
            <div class="dot"></div>
            <p>{{ date }}</p>
        </div>
        <div class="imageContainer">
            <img 
                class="image"
                :src="require(`@/assets/images/references/${image}`)" 
                alt=""
            >
            <div class="author">
                <span>{{ $t("referencesView.arhitectTitle") }}</span>
                <p class="architectName">{{architect}}</p>
            </div>
        </div>
        <div class="products">
            <div class="smallTitleContainer">
                <img class="logo" :src="require('@/assets/logo/blackIconOnly.svg')"/>
                <p class="smallTitle">{{ $t("referencesView.elementsTitle") }}</p>
            </div>
            <div class="singleProduct" @click="$emit('openProduct', product)" v-for="product of products" :key="product.id">
                <img class="icon" :src="require('@/assets/icons/iconArrow.svg')"/>
                <p>{{ product }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'referenceCard',
    props: {
        image: {},
        name: {},
        products: {},
        date: {},
        architect: {},
    },  
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>
.referencesCard {
    position: relative;
    width: 630px;
    border-radius: 10px;
    padding-bottom: 10px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    background: rgb(255, 255, 255);
    overflow: hidden;
}
.referencesCardSmall {
    width: 582px;
}
.referencesCard:hover {
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    background: white;
}
.wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-top: 0px;
}
.wrapper h2 {
    margin: 0;
    text-align: start;
    color: #4D455D;
    font-size: 24px;
}
.dot {
    width: 5px;
    height: 5px;
    margin-top: 17px;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 100%;
    background: rgb(245, 162, 53);
}
.wrapper p {
    text-align: center;
    font-size: 18px;
    color: gray;
    margin: 0;
    margin-top: 7px;
    margin-bottom: auto;
}
.imageContainer {
    position: relative;
    margin-top: 15px;
}
.image {
    margin-top: 0px;
    width: 100%;
    height: 300px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 5px;
}
.author {
    position: absolute;
    right: 0px;
    top: 20px;
    background: rgba(60, 73, 88, 0.7);
    padding: 30px 30px 10px 30px;
    border-radius: 5px 0px 0 5px;
    border-bottom: 0;
    border-left: 0;
}
.author p {
    font-weight: bold;
    color: rgb(255, 255, 255);
    font-size: 18px;
}
span {
    position: absolute;
    top: 5px;
    right: 5px;
    color: rgb(220, 220, 220);
    font-size: 12px;
}

.products {
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.products {
    margin-top: -5px;
    width: 99.73%;
    padding-bottom: 20px;
    border-radius: 0 0 10px 10px;
    background: #e2e4e9de;
    border: 1px solid rgb(240,240,240);
    border-top: none;
}
.smallTitleContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-top: 7px;
    padding-bottom: 10px;
    text-align: start;
    border-top: 1px solid rgb(230,230,230);
    border-bottom: 1px solid rgb(230,230,230);
    background: white;
}
.logo {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    margin-left: 24px;
    opacity: .9;
    width: 35px;
    height: 35px;
}
.smallTitle {
    padding: 0;
    margin: 0;
    padding-top: 10px;
    font-weight: bold;
    color: #424A5F;
    font-size: 20px;
}
.singleProduct {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 15px;
    padding: 10px 20px 10px 15px;
    border: 1px solid rgb(235,235,235);
    background: rgb(255,255,255);
    border-radius: 115px;
    margin-left: 22px;
}
/* .singleProduct:nth-child(2) {
    margin-left: 20px;
}
.singleProduct:nth-child(5) {
    margin-left: 20px;
}
.singleProduct:not(:nth-child(4), :nth-child(7)) {
    margin-right: 15px;
} */
.singleProduct p {
    font-size: 18px;
}
.singleProduct:hover {
    cursor: pointer;
    color: #2a4249;
    opacity: 1;
    background: rgb(250,250,250);
}
.icon {
    width: 20px;
    margin-right: 8px;
}
@media (max-width: 1350px) {
    .referencesCard {
        width: 49%;
    }
}
@media (max-width: 950px) {
    .referencesCard {
        width: 95%;
        margin: 0 auto;
    }
}
@media (max-width: 440px) {
    .referencesCard {
        width: 90%;
        margin: 0 auto;
    }
    .smallTitle {
        width: 87%;
    }
    .singleProduct {
        margin-left: 10px;
        margin-right: 5px;
    }
    .logo {
        margin-left: 14px;
}
}
</style>