<template>
    <div class="productCard">
        <img 
            class="image"
            :src="require(`@/assets/images/products/${imgAssetDir}/${image}`)" 
            alt=""
        >
        <h2>{{name}}</h2>
        <span class="text" v-html="shortDescription"></span>
        <span class="elementFooter">
            <span>{{button}}</span>
            <img 
                class="arrow"
                :src="require('@/assets/icons/arrow.svg')" 
                alt=""
            >
        </span>
    </div>
</template>

<script>
export default {
    name: 'productCard',
    props: {
        image: {},
        name: {},
        shortDescription: {},
        category: {},
        button: {},
        productUrl: {},
        imgAssetDir: {}
    },  
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>
.productCard {
    position: relative;
    width: 390px;
    height: 430px;
    border-radius: 5px 5px 20px 20px;
    -webkit-box-shadow: 0px 0px 10px 0px #e2e4e93d;
    box-shadow: 0px 0px 10px 0px #d8dbe4;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    background: rgb(255, 255, 255);
    overflow: hidden;
}
.productCard:hover {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    cursor: pointer;
    background: white;
}
.image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    margin: auto;
    width: 100%;
    height: 190px;
    -o-object-fit: cover;
       object-fit: cover;
    border-bottom: 1px solid rgb(180,180,180);
    -webkit-filter:brightness(.9);
            filter:brightness(.9);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.productCard:hover .image {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.productCard p {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    right: 10px;
    top: -54px;
    bottom: 0px;
    margin: auto;
    color: rgb(125, 125, 125);
    font-size: 14px;
    font-weight: bold;
    background: rgb(255, 255, 255);
    padding: 5px 20px 5px 20px;
    border-radius: 100px;
    border-top: 1px solid rgb(180,180,180);
}
.productCard h2 {
    position: absolute;
    width: 87%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: start;
    left: 0;
    right: 0;
    top: 20px;
    bottom: 0;
    margin: auto;
    color: rgb(50, 50, 50);
    font-size: 24px;
}
.text {
    position: absolute;
    top: 255px;
    left: 0;
    right: 0;
    margin: auto;
    width: 87%;
    text-align: start;
    color: rgb(100,100,100);
    display: -webkit-box; /* use the webkit box to define the number of lines */
    -webkit-line-clamp: 4; /* set the maximum number of lines */
    -webkit-box-orient: vertical; /* set the orientation of the box */
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis; /* add the ellipsis at the end of the text */
    overflow: hidden; /* hide any overflowing text */
}
.elementFooter {
    width: 100%;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 1px solid rgb(240,240,240);
    border-bottom: 1px solid rgb(240,240,240);
    background: #e2e4e93d;
    padding-top: 12px;
    padding-bottom: 12px;
}
.elementFooter span {
    font-size: 20px;
    color: #424A5F;
    font-weight: bold;
    margin-left: 25px;
}
.arrow {
    margin-right: 25px;
}
@media (max-width: 440px) {
    .productCard {
        width: 90%;
        height: 430px;
    }
}
</style>