<template>
    <div class="popupWrapper" :class="{'popupWrapperActive': popupTransitionActive}">
        <img 
            class="arrowIcon"
            @click="$emit('closePopup')"
            :src="require(`@/assets/icons/arrow.svg`)" 
            alt=""
        >
        <div class="contentBody" v-if="isContentVisible" @click="priceInquiryActive = false">
            <h2>{{ productData.name }}</h2>
            <p class="categoryName">{{ productData.category }}</p>
            <div class="scrollableBody" :style="{ height: (screenHeight - 240) + 'px' }">
                <div class="padding">
                    <imageCarousel
                        :carouselHeight='"330px"'
                        :slides='productData.refImages'
                        :productUrl="productData.productUrl"
                        :imgAssetDir="productData.imgAssetDir"
                    />
                    <p class="description" v-html="productData.description"></p>
                </div>
            </div>
            <div 
                class="darkBackground" 
                :class="{'darkBackgroundActive': priceInquiryActive}"
                v-if="isBackgroundActive"
                @click="closePriceInquiry"
            ></div>
        </div>
        <div class="inquiryContainer" :class="{ 'inquiryContainerActive': priceInquiryActive }">
            <span class="error" :class="{ 'errorActive': errorMessage }">{{ errorMessage }}</span>
            <button @click="priceInquiry">{{ $t("buttons.contact") }}</button>
            <img 
                class="closeXIcon"
                v-if="priceInquiryActive"
                @click="closePriceInquiry"
                :src="require(`@/assets/icons/form/closeX.svg`)" 
                alt=""
            >
            <p v-if="!inquirySent && priceInquiryActive" class="smallTitle">{{ $t("buttons.contact") }}</p>
            <div class="inputFields" v-if="!inquirySent && priceInquiryActive">
                <div class="singleInput">
                    <img class="inputIcon" :src="require('@/assets/icons/form/person.svg')"/>
                    <input type="text" @click="personError = false" :class="{ 'inputError':  personError}" :placeholder="$t(`form.name`)" v-model="person">
                </div>

                <div class="singleInput">
                    <img class="inputIcon" :src="require('@/assets/icons/form/business.svg')"/>
                    <input type="text" @click="businessError = false" :class="{ 'inputError':  businessError}" :placeholder="$t(`form.business`)" v-model="business">
                </div>

                <div class="singleInput">
                    <img class="inputIcon" :src="require('@/assets/icons/form/email.svg')"/>
                    <input type="text" @click="emailError = false" :class="{ 'inputError':  emailError}" :placeholder="$t(`form.email`)" v-model="email">
                </div>

                <div class="singleInput">
                    <img class="inputIcon" :src="require('@/assets/icons/form/phone.svg')"/>
                    <input type="text" @click="phoneError = false" :class="{ 'inputError':  phoneError}" :placeholder="$t(`form.mobile`)" v-model="phoneNr">
                </div>
                <textarea @click="letterError = false" :class="{ 'inputError':  letterError}" :placeholder="$t(`form.message`)" v-model="letter"></textarea>
            </div>
            <div class="successMessage" v-if="inquirySent && priceInquiryActive">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                <p>{{ $t("form.confirmationText") }}</p>
            </div>
            <span class="button" v-if="!inquirySent && priceInquiryActive" @click="sendInquiry()">{{ $t("buttons.sendMessage") }}</span>
        </div>
    </div>
</template>

<script>
import imageCarousel from '@/components/product/imageCarousel.vue'

export default {
    name: 'productPopup',
    components: {
        imageCarousel
    },
    props: {
        productData: {},
        isProductPopupActive: {}
    },
    watch: {
        isProductPopupActive(value) {
            if(value == true) {
                this.isContentVisible = true
                setTimeout(() => {
                    this.popupTransitionActive = true
                }, 100)
            }
            if(value == false) {
                this.popupTransitionActive = false
                this.priceInquiryActive = false
                setTimeout(() => {
                    this.isContentVisible = false
                }, 200)
            }
        }
    },
    data() {
        return {
            popupTransitionActive: false,
            isContentVisible: false,
            isBackgroundActive: false,

            priceInquiryActive: false,

            inquirySent: false,

            errorMessage: null,

            person: null,
            personError: false,

            business: null,
            businessError: false,

            email: null,
            emailError: false,

            phoneNr: null,
            phoneError: false,

            letter: null,
            letterError: false,

            screenHeight: null,
        }
    },  
    methods: {
        sendInquiry() {
            if(!this.person) {
                this.personError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.email) {
                this.emailError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.letter) {
                this.letterError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.errorMessage) {
                this.inquirySent = true
                this.person = null
                this.business = null
                this.email = null
                this.phoneNr = null
                this.letter = null

                setTimeout(() => {
                    this.closePriceInquiry()
                }, 2000)
            }
            if(this.errorMessage) {
                setTimeout(() => {
                    this.errorMessage = null
                }, 3000)
            }
        },

        priceInquiry() {
            this.$emit('closePopup')
            setTimeout(() => {
                this.$emit('openContactPopup')
            }, 210)
            // this.isBackgroundActive = true
            // setTimeout(() => {
            //     this.priceInquiryActive = true
            //     this.productName = this.productData.name
            //     this.productCategory = this.productData.category
            // }, 30)
        },

        closePriceInquiry() {
            this.priceInquiryActive = false
            this.inquirySent = false

            setTimeout(() => {
                this.isBackgroundActive = false
            }, 200)
        },

        handleResize() {
            this.screenHeight = window.innerHeight
        }
    },

    created() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    }
}
</script>
<style scoped>
.popupWrapper {
    position: fixed;
    overflow: hidden;
    right: -750px;
    top: 0;
    z-index: 100000;
    width: 625px;
    height: 100%;
    background: rgb(255,255,255);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    text-align: start;
}
.popupWrapperActive {
    right: 0px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s
}
h2 {
    margin: 0;
    margin-right: 10px;
    text-align: center;
    margin-top: 20px;
    color: #4D455D;
    font-size: 2rem;
    padding-right: 75px;
    padding-left: 75px;
}
.categoryName {
    text-align: center;
    font-size: 20px;
    color: gray;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 5px;
    padding-right: 75px;
    padding-left: 75px;
}
.arrowIcon {
    position: absolute;
    left: 25px;
    top: 30px;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: .8;
    cursor: pointer;
}
.contentBody {
    padding-left: 0px;
    padding-right: 0px;
}
.darkBackground {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.darkBackgroundActive {
    z-index: 10;
    background: rgba(0, 0, 0, 0.21);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.padding {
    padding-left: 75px;
    padding-right: 75px;
}
.scrollableBody {
    padding-bottom: 100px;
    overflow-y: auto;
    scrollbar-width: thin;
}
.description {
    padding-left: 5px;
    margin-top: 25px;
}
.inquiryContainer {
    overflow: hidden;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    background: rgb(255, 255, 255);
    /* -webkit-box-shadow: 0px -2px 5px -3px rgba(0,0,0,0.40);
    -moz-box-shadow: 0px -2px 5px -3px rgba(0,0,0,0.40);
    box-shadow: 0px -2px 5px -3px rgba(0,0,0,0.40); */
    width: 100%;
    height: 90px;
    text-align: center;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    border-radius: 0px;
}
.inquiryContainerActive {
    height: 480px;
    padding-top: 5px;
    -webkit-box-shadow: 0px -2px 5px -3px rgba(0,0,0,0.8);
    box-shadow: 0px -2px 5px -3px rgba(0,0,0,0.8);
    border-radius: 30px 30px 0px 0px;
}
.inquiryContainerActive button {
    position: absolute;
    top: -100px;
}
.closeXIcon {
    position: absolute;
    right: 27px;
    top: 23px;
    width: 18px;
    cursor: pointer;
}
.smallTitle {
    font-size: 1.2rem;
    color: #4d455dea;
    text-align: left;
    margin-left: 50px;
    margin-top: 13px;
    font-family: 'DM sans', sans-serif;
}
button {
    margin-top: 20px;
    width: 320px;
    height: 40px;
    border-radius: 100px;
    background: #4D455D;
    color: #ffffff;
    border: 0px solid #4D455D;
    font-size: 21px;
    cursor: pointer;
    -webkit-transition: 2s;
    -o-transition: 2s;
    transition: 2s;
}
button:hover {
    background: #133d6dee;
}
.inputFields {
    padding-left: 50px;
    padding-right: 50px;
    margin: 0 auto;
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.singleInput {
    position: relative;
    width: 45%;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 15px;
}
.inputIcon {
    position: absolute;
    margin-left: 0px;
    margin-top: 11px;
    z-index: 20;
    width: 25px;
    opacity: 1;
}
input {
    position: relative;
    z-index: 10;
    height: 50px;
    width: 97%;
    text-indent: 40px;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #4d455d7c;
    color: #4D455D;
}
textarea {
    height: 150px;
    margin-top: 10px;
    padding-top: 15px;
    padding-left: 4%;
    padding-right: 4%;
    width: 92%;
    border-radius: 10px;
    border: 1px solid #4d455d7c;
}
input:focus {
    outline: none;
    border-bottom: 1px solid #4D455D;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
textarea:focus {
    outline: none;
    border: 1px solid #4D455D;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::-moz-placeholder, input::-moz-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea:-ms-input-placeholder, input:-ms-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::-webkit-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::-moz-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea:-ms-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::-ms-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea {
    width: 100%;
    resize: none;
    margin-bottom: 30px;
    color: #4D455D;
}
.successMessage {
    position: relative;
    z-index: 100;
}
.successMessage p {
    position: absolute;
    left: 0;
    right: 0;
    top: 300px;
    padding-left: 25px;
    padding-right: 25px;
    margin: auto;
    z-index: 1000;
    margin-top: 0px;
    font-size: 28px;
    color: #64C3A2;
}
.button {
    margin-top: 50px;
    border: 1px solid #4D455D;
    background: #4D455D;
    color: white;
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 20px;
}
.button:hover {
    cursor: pointer;
    opacity: .9;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #64C3A2;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    position: absolute;
    left: 0;
    right: 0;
    top: 75px;
    margin: auto;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #64C3A2;
    stroke-miterlimit: 10;
    -webkit-box-shadow: inset 0px 0px 0px #64C3A2;
            box-shadow: inset 0px 0px 0px #64C3A2;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.inputError::-webkit-input-placeholder {
    color: #a11e34;
    -webkit-transition: .2s;
    transition: .2s;
}
.inputError::-moz-placeholder {
    color: #a11e34;
    -moz-transition: .2s;
    transition: .2s;
}
.inputError:-ms-input-placeholder {
    color: #a11e34;
    -ms-transition: .2s;
    transition: .2s;
}
.inputError::-ms-input-placeholder {
    color: #a11e34;
    -ms-transition: .2s;
    transition: .2s;
}
.inputError::placeholder {
    color: #a11e34;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.inputError {
    border-bottom: 1px solid #E76161;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.error {
    position: absolute;
    z-index: 100;
    top: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    background: #E76161;
    color: white;
    font-size: 18px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.errorActive {
    top: 0px;
    left: 0;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
@-webkit-keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@-webkit-keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 63px #ffffff;
                box-shadow: inset 0px 0px 0px 63px #ffffff;
    }
}
@keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 63px #ffffff;
                box-shadow: inset 0px 0px 0px 63px #ffffff;
    }
}
@media (max-width: 625px) {
    .popupWrapper {
        width: 100%;
    }
    h2 {
        font-size: 1.8rem;
        padding-right: 45px;
        padding-left: 45px;
    }
    .categoryName {
        padding-right: 25px;
        padding-left: 25px;
    }
    .padding {
        padding-left: 25px;
        padding-right: 25px;
    }
    .arrowIcon {
        left: 15px;
        top: 31px;
    }
    button {
        margin-top: 10px;
    }
    .inputFields {
        padding-left: 25px;
        padding-right: 25px;
    }
    .singleInput {
        width: 47%;
        margin-bottom: 15px;
    }
    input {
        width: 97%;
        text-indent: 30px;
    }
    .smallTitle {
        margin-left: 25px;
    }
    .inquiryContainer {
        height: 60px;
    }
    .inquiryContainerActive {
        height: 480px;
    }
}
</style>